import { Injectable }               from '@angular/core';
import { Globals }                  from '../common/globals';
import { HttpClient }               from '@angular/common/http';
import { Observable }               from 'rxjs';
import { CommonService }            from './common.service';
import { Segment } from '../statistics/segments/model/segment.model';
import { RunSegmentResult } from '../statistics/segments/model/run-segment-result.model';
import { RunSegmentRequest } from '../statistics/segments/model/run-segment-request.model';
import { Queryable } from '../common/report-grid/model/queryable.model';
import { SegmentHistory } from '../statistics/segments/model/segment-history.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SegmentService {
    public readonly localPort = 8377;
    private _commonService: CommonService;
    private timeout = 120000;

    constructor(http: HttpClient, globals: Globals, snackbar: MatSnackBar) {
        this._commonService = new CommonService(http, globals, 'segment', this.localPort, 'TenantReports', true);
        // the authorozatopm is removed by the interceptor when accessing different domain
        // this issue has no other solution then this one, the whitelisted domain property can't be used
        this._commonService.getCustomHeaders = () => {
            return { 'Authorization': globals.getAuthorizationHeaderValue() };
        };
    }

    public listAll(): Observable<Segment[]> {
        return this._commonService.get('');
    }

    public listAllSimple(): Observable<Segment[]> {
        return this._commonService.get('');
    }

    public getByID(id: string): Observable<Segment> {
        return this._commonService.get(id);
    }

    public getByName(name: string): Observable<Segment> {
        return this._commonService.get('byName/' + name);
    }

    public getHistory(id: number): Observable<SegmentHistory[]> {
        return this._commonService.get('getHistory/' + id);
    }

    public upsert(segment: Segment) {
        return this._commonService.post('', segment);
    }

    public delete(id: number) {
        return this._commonService.delete(id.toString());
    }

    public runSegment(request: RunSegmentRequest): Observable<RunSegmentResult> {
        return this._commonService.post('RunSegment', request, this.timeout);
    }

    public deleteSession(sessionUID: string) {
        return this._commonService.delete('deleteSession/' + sessionUID);
    }

    public getFilterQueryables(): Observable<Queryable[]> {
        return this._commonService.get('FilterQueryables');
    }

    public updateSegmentDates(ids: Array<number>) {
        return this._commonService.post('UpdateSegmentDates', ids, this.timeout);
    }
}
